(function(w, d) {
    w.sticky_footer_promo_config.forEach(function(o) {
        var footerPromo = document.querySelector('#sticky-footer-promo-' + o.block_id);
        var dismissPromoButton = document.querySelector('#sticky-footer-promo-dismiss-button-' + o.block_id);
        var layoutTimeout = Number(o.layout_timeout) * 1000;
        
        function footerPromoDismissClicked() {      
            footerPromo.classList.add("sticky-footer-promo-hidden");
            localStorage.setItem("tnt-hide-sticky-footer-" + o.block_id, true);
        }
            
        function footerPromoStorageClearing() {
            // to clear the localStorage after 24 hour
            // (if someone want to clear after 8hrs simply change nHours = 8 and so on)
            // This could also be made dynamic in the future for customers if they want to change the hours in the block settings
            var nHours = 24;
            var now = new Date().getTime();
            var setUpTime = localStorage.getItem('tnt-sticky-footer-promo-display-time-' + o.block_id);
                
            if (setUpTime == null) {
                localStorage.setItem('tnt-sticky-footer-promo-display-time-' + o.block_id, now);
            } else {
                if(now - setUpTime > nHours*60*60*1000) {
                    localStorage.removeItem('tnt-hide-sticky-footer-' + o.block_id);
                    localStorage.setItem('tnt-sticky-footer-promo-display-time-' + o.block_id, now);
                }
            }
        }
            
        function footerPromoDelay() {
            isDismissed = localStorage.getItem('tnt-hide-sticky-footer-' + o.block_id);
            
            if (!isDismissed) {
                footerPromo.classList.add('sticky-footer-promo-show');
            }
        }
            
        function footerDismissButton() {
            if (dismissPromoButton) {
                dismissPromoButton.addEventListener("click", function() {
                    event.preventDefault();
                    footerPromoDismissClicked();
                })
            }
                
            if (localStorage.getItem('tnt-hide-sticky-footer-' + o.block_id)) {
                footerPromo.style.display = 'none';
            }
        }
            
        window.addEventListener('DOMContentLoaded', function() {
            footerDismissButton();
            footerPromoStorageClearing();
            setTimeout(footerPromoDelay, layoutTimeout);
        })
    })
})(window, document)
